<template>
  <div class="app-container">
    <CrudTable entity="Product" :columns="columns" :default-filters="{ onlineYN: 1 }" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import { booleanColumnFilter, booleanColumnFormatter } from '@/utils/filters';
import { formatPrice } from '../../../utils';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        'name',
        {
          field: 'menuCategoryName',
          label: 'common.menuCategory',
          filter: {
            type: 'select',
            options: this.menuCategories,
            valueField: 'name',
            labelField: 'name'
          }
        },
        {
          field: 'productCategoryName',
          label: 'common.productCategory',
          filter: {
            type: 'select',
            options: this.productCategories,
            valueField: 'name',
            labelField: 'name'
          }
        },
        {
          field: 'toGoYN',
          label: 'common.toGo',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        },
        'ean',
        'position',
        {
          field: 'onlineYN',
          label: 'common.onlineYN',
          filter: booleanColumnFilter(this),
          formatter: booleanColumnFormatter(this)
        },
        {
          sortable: false,
          filter: { type: 'none' },
          field: 'deposit',
          label: 'product.deposit',
          formatter: this.depositFormatter
        }
      ]
    };
  },
  computed: {
    menuCategories() {
      return this.$store.getters['menuCategory/loadedItems'];
    },
    productCategories() {
      return this.$store.getters['productCategory/loadedItems'];
    }
  },
  watch: {
    menuCategories(menuCategories) {
      this.getColumn('menuCategoryName').filter.options = menuCategories;
    },
    productCategories(productCategories) {
      this.getColumn('productCategoryName').filter.options = productCategories;
    }
  },
  created() {
    this.$store.dispatch('menuCategory/getItems');
    this.$store.dispatch('productCategory/getItems');
  },
  methods: {
    getColumn(fieldName) {
      return this.columns.find(c => c.field === fieldName);
    },
    depositFormatter(row, column, cellValue) {
      let returnString = '';

      if (cellValue !== null) {
        const translations = (cellValue.translations || []).slice(0).sort((a, b) => {
          return a.languageCode > b.languageCode ? -1 : 1;
        });
        for (let i = 0; i < translations.length; i++) {
          returnString = returnString.concat(this.toSingleLanguageDepositString(translations[i]));
        }
      }
      return returnString;
    },
    toSingleLanguageDepositString(translation) {
      if (translation.number === null) {
        return '';
      }
      return translation.languageCode.toUpperCase() + ': ' + formatPrice((translation.number || 0) / 100) + '; ';
    }
  }
};
</script>

<style scoped></style>
